import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DefaultComponent} from './pages/default/default.component';
import { environment } from '../environments/environment';
import {LangGuard} from './_guards/LangGuard';
import {Error404Component} from './_shared/errors/error404/error404.component';
import {DefaultLangGuard} from '@guards/DefaultLangGuard';
import {AuthGuard} from '@guards/AuthGuard';
import { RedirectorComponent } from '@pages/redirector/redirector.component';
import {OurPhilosophyModule} from '@pages/our-philosophy/our-philosophy.module';

const defaultRoute = '/' + environment.defaultLanguage;

/**
 * !!! DONT FORGET TO UPDATE RouteMapHelper
 */
const routes: Routes = [
  { path: environment.routeLanguageKey, component: DefaultComponent, canActivate: [LangGuard], children: [
      { path: '', data: {
          isHomepageRoute: true,
        },
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
      },

      { path: 'contact', canActivate: [LangGuard], data: {
          forLanguages: ['en'],
          group: 'contact'
        },
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule)
      },

      { path: 'kontakt', canActivate: [LangGuard], data: {
          forLanguages: ['pl', 'de'],
          group: 'contact'
        },
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule)
      },

      { path: 'gallery', canActivate: [LangGuard], data: {
          forLanguages: ['en'],
          group: 'gallery'
        },
        loadChildren: () => import('./pages/gallery/gallery.module').then(m => m.GalleryModule)
      },

      { path: 'galeria', canActivate: [LangGuard], data: {
          forLanguages: ['pl'],
          group: 'gallery'
        },
        loadChildren: () => import('./pages/gallery/gallery.module').then(m => m.GalleryModule)
      },

      { path: 'galerie', canActivate: [LangGuard], data: {
          forLanguages: ['de'],
          group: 'gallery'
        },
        loadChildren: () => import('./pages/gallery/gallery.module').then(m => m.GalleryModule)
      },

      { path: 'events', canActivate: [LangGuard], data: {
          forLanguages: ['en'],
          group: 'events'
        },
        loadChildren: () => import('./pages/events/events.module').then(m => m.EventsModule)
      },
      { path: 'wydarzenia', canActivate: [LangGuard], data: {
          forLanguages: ['pl'],
          group: 'events'
        },
        loadChildren: () => import('./pages/events/events.module').then(m => m.EventsModule)
      },
      { path: 'veranstaltungen', canActivate: [LangGuard], data: {
          forLanguages: ['de'],
          group: 'events'
        },
        loadChildren: () => import('./pages/events/events.module').then(m => m.EventsModule)
      },

      { path: 'career', canActivate: [LangGuard], data: {
          forLanguages: ['en'],
          group: 'career'
        },
        loadChildren: () => import('./pages/career/career.module').then(m => m.CareerModule)
      },
      { path: 'kariera', canActivate: [LangGuard], data: {
          forLanguages: ['pl'],
          group: 'career'
        },
        loadChildren: () => import('./pages/career/career.module').then(m => m.CareerModule)
      },
      { path: 'karriere', canActivate: [LangGuard], data: {
          forLanguages: ['de'],
          group: 'career'
        },
        loadChildren: () => import('./pages/career/career.module').then(m => m.CareerModule)
      },

      { path: 'wellness-and-spa', canActivate: [LangGuard], data: {
          forLanguages: ['en'],
          group: 'wellness',
          meta: {
            title: 'wellness_and_spa',
          }
        },
        loadChildren: () => import('./pages/wellness/wellness.module').then(m => m.WellnessModule)
      },
      { path: 'wellness-und-spa', canActivate: [LangGuard], data: {
          forLanguages: ['de'],
          group: 'wellness',
          meta: {
            title: 'wellness_and_spa',
          }
        },
        loadChildren: () => import('./pages/wellness/wellness.module').then(m => m.WellnessModule)
      },
      { path: 'wellness-i-spa', canActivate: [LangGuard], data: {
          forLanguages: ['pl'],
          group: 'wellness',
          meta: {
            title: 'wellness_and_spa',
          }
        },
        loadChildren: () => import('./pages/wellness/wellness.module').then(m => m.WellnessModule)
      },

      { path: 'offer', canActivate: [LangGuard], data: {
          forLanguages: ['en'],
          group: 'offer'
        },
        loadChildren: () => import('./pages/offer/offer.module').then(m => m.OfferModule)
      },

      { path: 'angebot', canActivate: [LangGuard], data: {
          forLanguages: ['de'],
          group: 'offer'
        },
        loadChildren: () => import('./pages/offer/offer.module').then(m => m.OfferModule)
      },

      { path: 'oferta', canActivate: [LangGuard], data: {
          forLanguages: ['pl'],
          group: 'offer'
        },
        loadChildren: () => import('./pages/offer/offer.module').then(m => m.OfferModule)
      },

      { path: 'food-and-wine', canActivate: [LangGuard], data: {
          forLanguages: ['pl', 'en', 'de'],
          group: 'food',
          meta: {
            title: 'food_and_wine',
          }
        },
        loadChildren: () => import('./pages/food/food.module').then(m => m.FoodModule)
      },

      { path: 'rooms', canActivate: [LangGuard], data: {
          forLanguages: ['en'],
          group: 'room'
        },
        loadChildren: () => import('./pages/room/room.module').then(m => m.RoomModule)
      },

      { path: 'zimmer', canActivate: [LangGuard], data: {
          forLanguages: ['de'],
          group: 'room'
        },
        loadChildren: () => import('./pages/room/room.module').then(m => m.RoomModule)
      },

      { path: 'pokoje', canActivate: [LangGuard], data: {
          forLanguages: ['pl'],
          group: 'room'
        },
        loadChildren: () => import('./pages/room/room.module').then(m => m.RoomModule)
      },

      { path: 'food-and-wine', canActivate: [LangGuard], data: {
          forLanguages: ['pl'],
          group: 'food'
        },
        loadChildren: () => import('./pages/food/food.module').then(m => m.FoodModule)
      },

      { path: 'preview', canActivate: [LangGuard], data: {
          forLanguages: ['pl', 'de', 'en'],
          group: 'preview'
        },
        loadChildren: () => import('./pages/preview/preview.module').then(m => m.PreviewModule)
      },

      { path: 'oferta-specjalna', canActivate: [LangGuard], data: {
          forLanguages: ['pl'],
          group: 'specialOffer'
        },
        loadChildren: () => import('./pages/special-offer/special-offer.module').then(m => m.SpecialOfferModule)
      },

      { path: 'sonderangebot', canActivate: [LangGuard], data: {
          forLanguages: ['de'],
          group: 'specialOffer'
        },
        loadChildren: () => import('./pages/special-offer/special-offer.module').then(m => m.SpecialOfferModule)
      },

      { path: 'special-offer', canActivate: [LangGuard], data: {
          forLanguages: ['en'],
          group: 'specialOffer'
        },
        loadChildren: () => import('./pages/special-offer/special-offer.module').then(m => m.SpecialOfferModule)
      },

      { path: 'praca-zdalna', canActivate: [LangGuard], data: {
          forLanguages: ['pl'],
          group: 'remote-work-group'
        },
        loadChildren: () => import('./pages/remote-work/remote-work.module').then(m => m.RemoteWorkModule)
      },

      { path: 'remote-work', canActivate: [LangGuard], data: {
          forLanguages: ['en'],
          group: 'remote-work-group'
        },
        loadChildren: () => import('./pages/remote-work/remote-work.module').then(m => m.RemoteWorkModule)
      },

      { path: 'fernarbeit', canActivate: [LangGuard], data: {
          forLanguages: ['de'],
          group: 'remote-work-group'
        },
        loadChildren: () => import('./pages/remote-work/remote-work.module').then(m => m.RemoteWorkModule)
      },

      { path: 'karta-podarunkowa', canActivate: [LangGuard], data: {
          forLanguages: ['pl'],
          group: 'gift-cards-group'
        },
        loadChildren: () => import('./pages/gift-card/gift-card.module').then(m => m.GiftCardModule)
      },

      { path: 'gift-card', canActivate: [LangGuard], data: {
          forLanguages: ['en'],
          group: 'gift-cards-group'
        },
        loadChildren: () => import('./pages/gift-card/gift-card.module').then(m => m.GiftCardModule)
      },

      { path: 'geschenkgutschein', canActivate: [LangGuard], data: {
          forLanguages: ['de'],
          group: 'gift-cards-group'
        },
        loadChildren: () => import('./pages/gift-card/gift-card.module').then(m => m.GiftCardModule)
      },

      { path: 'bookings', canActivate: [LangGuard], data: {
          forLanguages: ['en'],
          group: 'reservation'
        },
        loadChildren: () => import('./_subprojects/reservation-form/reservation-form.module').then(m => m.ReservationFormModule)
      },
      { path: 'buchungen', canActivate: [LangGuard], data: {
          forLanguages: ['de'],
          group: 'reservation'
        },
        loadChildren: () => import('./_subprojects/reservation-form/reservation-form.module').then(m => m.ReservationFormModule)
      },
      { path: 'rezerwacje', canActivate: [LangGuard], data: {
          forLanguages: ['pl'],
          group: 'reservation'
        },
        loadChildren: () => import('./_subprojects/reservation-form/reservation-form.module').then(m => m.ReservationFormModule)
      },

      { path: 'my-reservations', canActivate: [LangGuard], data: {
        forLanguages: ['en'],
        group: 'my-reservation',
        blockedScroll: true
      },
        loadChildren: () => import('./_subprojects/reservation/_pages/reservations.module').then(m => m.ReservationsModule)
      },
      { path: 'meine-reservierungen', canActivate: [LangGuard], data: {
          forLanguages: ['de'],
          group: 'my-reservation',
          blockedScroll: true
        },
        loadChildren: () => import('./_subprojects/reservation/_pages/reservations.module').then(m => m.ReservationsModule)
      },
      { path: 'blogostan', canActivate: [LangGuard], data: {
          forLanguages: ['pl'],
          group: 'blogostan',
          blockedScroll: true
        },
        loadChildren: () => import('./_subprojects/blogostan/_pages/blogostan.module').then(m => m.BlogostanModule)
      },
      { path: 'moje-rezerwacje', canActivate: [LangGuard], data: {
          forLanguages: ['pl'],
          group: 'my-reservation',
          blockedScroll: true
        },
        loadChildren: () => import('./_subprojects/reservation/_pages/reservations.module').then(m => m.ReservationsModule)
      },
      { path: 'nasza-filozofia', canActivate: [LangGuard], data: {
          forLanguages: ['pl'],
          group: 'our-philosophy'
        },
        loadChildren: () => import('./pages/our-philosophy/our-philosophy.module').then(m => m.OurPhilosophyModule)
      },
      { path: 'our-philosophy', canActivate: [LangGuard], data: {
          forLanguages: ['en'],
          group: 'our-philosophy'
        },
        loadChildren: () => import('./pages/our-philosophy/our-philosophy.module').then(m => m.OurPhilosophyModule)
      },
      { path: 'unsere-philosophie', canActivate: [LangGuard], data: {
          forLanguages: ['de'],
          group: 'our-philosophy'
        },
        loadChildren: () => import('./pages/our-philosophy/our-philosophy.module').then(m => m.OurPhilosophyModule)
      },
      { path: 'polityka-prywatnosci', canActivate: [LangGuard], data: {
          forLanguages: ['pl'],
          group: 'privacy-policy'
        },
        loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
      },
      { path: 'privacy-policy', canActivate: [LangGuard], data: {
          forLanguages: ['en'],
          group: 'privacy-policy'
        },
        loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
      },
      { path: 'datenschutzbestimmungen', canActivate: [LangGuard], data: {
          forLanguages: ['de'],
          group: 'privacy-policy'
        },
        loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
      },

      // { path: 'o-blogostanie', canActivate: [LangGuard], data: {
      //     forLanguages: ['pl'],
      //     group: 'about-blogostan'
      //   },
      //   loadChildren: () => import('./pages/blogostan/blogostan.module').then(m => m.BlogostanModule)
      // },

      { path: '404', component: Error404Component },

      { path: 'redirector', canActivate: [LangGuard], component: RedirectorComponent, data: {
        omitGTM: true, forLanguages: ['pl', 'en', 'de'],
      },
    },
  ] },
  { path: '', component: DefaultComponent, pathMatch: 'full', canActivate: [DefaultLangGuard] },
  { path: '**', component: Error404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export {
  routes
};

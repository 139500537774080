import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  Inject,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit, AfterContentChecked
} from '@angular/core';
import {ReservationDataService} from '@services/reservations/reservation-data.service';
import IReservationRoom from '../../_interfaces/IReservationRoom';
import {ReservationService} from '../../_services/reservation.service';
import * as moment from 'moment';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {finalize, takeUntil} from 'rxjs/operators';
import IReservationPricePerRoom from '../../_interfaces/IReservationPricePerRoom';
import * as toArray from 'lodash/toArray';
import * as cloneDeep from 'lodash/cloneDeep';
import * as difference from 'lodash/difference';
import IReservationRoomStandard from '../../_interfaces/IReservationRoomStandard';
import IReservationRoomStandardPrice from '../../_interfaces/IReservationRoomStandardPrice';
import IReservationRoomStandardPricePerRoom from '../../_interfaces/IReservationRoomStandardPricePerRoom';
import {PopupContentComponent} from '../../../reservation/_shared/popup-content/popup-content.component';
import {NewRoomService} from '@services/new-room.service';
import {INewRoomDetails, INewRoomTag} from '@interfaces/INewRoom';
import {IImage} from '@interfaces/IImage';
import {ReservationRoomsService} from '../../_services/reservation-rooms.service';
import {LayoutService} from '@services/layout.service';
import ReservationStepEnum from '../../_enums/ReservationStepEnum';
import { ReservationStepService } from '@services/reservations/reservation-step.service';
import IReservationStep from '../../_interfaces/IReservationStep';
import { ValidationEmitterService } from '../../_services/validation-emitter.service';
import { DOCUMENT } from '@angular/common';
import ReservationAvailableTypeEnum from '../../_enums/ReservationAvailableTypeEnum';
import { ActivatedRoute } from '@angular/router';
import GlobalFunctions from '@helpers/GlobalFunctions';
import {RoomTabComponent} from './_shared/room-tab/room-tab.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss']
})
export class StepTwoComponent extends SubscribeHelper implements OnInit, OnDestroy {
  rooms: IReservationRoom[] = [];
  roomsCopy: IReservationRoom[] = [];
  activeRoomIndex = null;
  dateFrom: string;
  dateTo: string;
  booking: IReservationRoomStandardPricePerRoom = null;
  standardRooms: IReservationRoomStandardPrice[] = [];
  allStandardRooms: IReservationRoomStandard[] = [];
  allStandardRoomsCurrent: IReservationRoomStandard[] = [];
  buttonBlocked = false;

  roomDetails: INewRoomDetails = null;
  roomTags: INewRoomTag[] = [];

  // msgVisible = false;
  nextStepMsg: string = null;

  @ViewChild('popup') popup: PopupContentComponent;
  @ViewChild('roomTabs', { read: ElementRef }) roomTabs: ElementRef;

  constructor(
    private reservationData: ReservationDataService,
    private reservation: ReservationService,
    private changeDetectorRef: ChangeDetectorRef,
    private newRoomService: NewRoomService,
    private reservationRoomsService: ReservationRoomsService,
    private layoutService: LayoutService,
    private stepService: ReservationStepService,
    private validationEmitterService: ValidationEmitterService,
    private reservationDataService: ReservationDataService,
    @Inject(DOCUMENT) private document
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToDate();
    this.subscribeToRooms();
    this.subscribeToRoomToShow();
    this.subscribeToStepsChange();
    this.subscribeToStepThree();
  }

  subscribeToStepsChange() {
    this.stepService
      .getSteps()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((steps: IReservationStep[]) => {
        this.buttonBlocked = !steps.find(step => step.id === ReservationStepEnum.II).isValid;

        if ( !this.buttonBlocked ) {
          this.nextStepMsg = null;
        }
      });
  }

  subscribeToRooms(): void {
    this.reservationData
      .getRooms()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe( rooms => {
        if ( JSON.stringify(rooms) !== JSON.stringify(this.roomsCopy) ) {
          if ( this.booking && this.allStandardRooms ) {
            this.saveUsedRooms();
          }

          let canMakeRequest = true;

          rooms.forEach( (room, index) => {
            if ( canMakeRequest ) {
              const newRooms = cloneDeep(rooms);
              const oldRoom = this.roomsCopy[index] || null;
              const newRoom = newRooms[index] || null;

              canMakeRequest = this.canMakeRequest(oldRoom, newRoom);
            }
          });

          this.rooms = rooms; // cloneDeep bo nie wykrywało zmian
          this.roomsCopy = cloneDeep(rooms);

          if ( canMakeRequest ) {
            this.getRoomsStandards(this.dateFrom, this.dateTo, this.rooms);
          }

          if ( this.activeRoomIndex === null && this.rooms.length === 1 ) {
            this.activeRoomIndex = 0;
          }

          this.changeDetectorRef.detectChanges();
        }
      });
  }

  subscribeToRoomToShow(): void {
    this.reservationRoomsService
      .getRoomToShow()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe( slug => {
        this.getRoomDetails( slug );
      });
  }

  subscribeToDate(): void {
    this.reservationData
      .getDate()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe( date => {
        if ( typeof date.from !== 'undefined' && typeof date.to !== 'undefined' ) {
          if ( date.from && date.to ) {
            this.dateFrom = moment([date.from.year, date.from.month - 1, date.from.day]).format('YYYY-MM-DD');
            this.dateTo = moment([date.to.year, date.to.month - 1, date.to.day]).format('YYYY-MM-DD');

            this.getRoomsStandards( this.dateFrom, this.dateTo, this.rooms );
          }
        }
      });
  }

  getRoomsStandards( dateFrom: string, dateTo: string, rooms: IReservationRoom[] ): void {
    if  ( this.dateFrom && this.dateTo && rooms.length > 0 ) {
      const coupon = this.reservationData.data.coupon$.value;
      const reservationOfferId = this.reservationData.data.reservationOffer$.value?.offerId;

      this.reservation
        .getRoomsStandards( dateFrom, dateTo, rooms, coupon, reservationOfferId )
        .pipe(
          takeUntil(this.componentDestroyed$)
        )
        .subscribe( data => {
          this.booking = data.booking;
          this.allStandardRooms = toArray(data.rooms);
          this.saveUsedRooms();
        });
    }
  }

  /**
   * Usuwanie pokoi, jeśli któryś jest niedostępny.
   *
   * Odejmij od liczby pokoi liczbę wybranych pokoi
   * w tym samym standardzie. Jeśli pokój został
   * wybrany, ale w standardzie nie ma już wolnych
   * pokoi, usuń pokój z wybranych.
   *
   * Akcja dzieje się po zmianie na pokojach (po zmianie standardu),
   * oraz po pobraniu z serwera standardów pokoi.
   *
   * Podobna logika w lowest-price-summary.component.ts
   */
  saveUsedRooms() {
    this.allStandardRoomsCurrent = cloneDeep(this.allStandardRooms);
    let save = false;

    this.rooms.forEach((room, index) => {
      const standard = this.allStandardRoomsCurrent.find( el => el.shuumStandardId === room.standardId );
      const booking = this.booking?.rooms[index];

      if ( GlobalFunctions.checkRoomsAvailability(standard, booking, room) ) {
        save = true;
      }
    });

    if ( save ) {
      this.reservationData.setRooms(this.rooms);
    }
  }

  setActiveRoomIndex( index: number ) {
    this.activeRoomIndex = this.activeRoomIndex === index ? null : index;

    if ( this.activeRoomIndex !== null && typeof window !== 'undefined' ) {
      const topOffset: number = this.roomTabs.nativeElement.getBoundingClientRect().top;
      const scrollTopPosition: number = window.scrollY;

      GlobalFunctions.scrollToValue( topOffset + scrollTopPosition - 80 );
    }
  }

  canMakeRequest(oldRoom: IReservationRoom, newRoom: IReservationRoom): boolean {
    const paramsToDelete = [
      'standardId',
      'name',
      'image',
      'price',
      'priceType',
      'advancePercent',
      'offer',
      'extras',
      'withDinner'
    ];

    if ( oldRoom ) {
      paramsToDelete.forEach( param => {
        if (typeof oldRoom[param] !== 'undefined') {
          delete oldRoom[param];
        }
      });
    }

    if ( newRoom ) {
      paramsToDelete.forEach( param => {
        if (typeof newRoom[param] !== 'undefined') {
          delete newRoom[param];
        }
      });
    }

    return JSON.stringify(oldRoom) !== JSON.stringify(newRoom);
  }

  getRoomDetails( slug: string ): void {
    this.layoutService.setItemLoader( true );
    this.newRoomService.getDetails( slug )
      .pipe(
        takeUntil(this.componentDestroyed$),
        finalize(() => {
          this.layoutService.setItemLoader( false );
        })
      )
      .subscribe( details => {
        this.roomDetails = details;
        this.roomTags = this.roomDetails.tags;

        this.popup.showPopup();
      });
  }

  /**
   * Po kliknięciu w przycisk dalej
   * lub przejściu do kroku 3 poprzez nawigację boczną,
   * pokaż błędy na kroku
   */
  subscribeToStepThree() {
    this.validationEmitterService
      .getSecondStepValidationToggle()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(res => {
        this.nextStepMsg = res;

        if ( typeof window.scrollTo === 'function' ) {
          const supportsNativeSmoothScroll = 'scrollBehavior' in this.document.documentElement.style;

          if ( supportsNativeSmoothScroll ) {
            window.scrollTo({
              top: this.document.documentElement.scrollHeight,
              behavior: 'smooth'
            });
          }
          else {
            window.scrollTo(0, this.document.documentElement.scrollHeight);
          }
        }
      });
  }
}

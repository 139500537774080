import {Component, Input, OnInit} from '@angular/core';
import IPrice from '@interfaces/IPrice';
import TextOfferEnum from '@enums/TextOfferEnum';

@Component({
  selector: 'app-text-offer',
  templateUrl: './text-offer.component.html',
  styleUrls: ['./text-offer.component.scss']
})
export class TextOfferComponent implements OnInit {
  @Input() image: string;
  @Input() imageAlt: string;
  @Input() header: string;
  @Input() isWellness?: boolean;
  @Input() isSingledOut?: boolean;
  @Input() isBestseller?: boolean;
  @Input() isNew?: boolean;
  @Input() price?: IPrice;
  @Input() type?: TextOfferEnum;
  @Input() callendar?: string;

  @Input() link?: string;
  @Input() itemId?: number;
  @Input() onLinkClick?: (id: number) => void;

  constructor() {}

  ngOnInit() {
    if ( typeof this.isWellness !== 'undefined' ) {
      if ( this.isWellness !== false ) {
        this.isWellness = true;
      }
    }

    if ( typeof this.isSingledOut !== 'undefined' ) {
      if ( this.isSingledOut !== false ) {
        this.isSingledOut = true;
      }
    }
  }

}

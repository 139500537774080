import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Inject, NgModule, Optional, PLATFORM_ID } from '@angular/core';
import {BrowserTransferStateModule, makeStateKey, TransferState} from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Request } from 'express';

import { translateLoaderFactory } from './translate-loaders';
import ConfigService from '../_config/ConfigService';
import GlobalFunctions from '../_helpers/GlobalFunctions';
import { LoaderInterceptor } from '../_interceptors/LoaderInterceptor';

@NgModule({
  imports: [
    BrowserTransferStateModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient, TransferState, PLATFORM_ID]
      }
    })
  ],
  exports: [TranslateModule]
})
export class I18nModule {
  constructor(
    translate: TranslateService,
    transferState: TransferState,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platform: any,
    @Optional() @Inject(REQUEST) private req: Request
  ) {
    const langs = ConfigService.getLanguages();
    let defaultLang = ConfigService.getDefaultLang();

    translate.addLangs(langs);

    if ( isPlatformBrowser(this.platform) ) {
      const userLang = translate.getBrowserLang();

      if ( GlobalFunctions.isLangAvailable(userLang) ) {
        defaultLang = userLang;
      }

      if ( typeof sessionStorage !== 'undefined' ) {
        const userLangStorage = sessionStorage.getItem('lang');

        if ( GlobalFunctions.isLangAvailable(userLangStorage) ) {
          defaultLang = userLangStorage;
        }
      }

      const key = makeStateKey<any>('lang');
      const serverLang = transferState.get(key, defaultLang);

      if ( GlobalFunctions.isLangAvailable(serverLang) ) {
        translate.use(serverLang);
        ConfigService.setCurrentLang( serverLang );
      }
      else {
        translate.use(defaultLang);
        ConfigService.setCurrentLang( defaultLang );
      }
    }
    else {
      const acceptLanguage: string = this.req.headers['accept-language'];
      let userLang: string = defaultLang;

      if ( acceptLanguage ) {
        const languages: string[] = acceptLanguage.match(/[a-zA-Z\-]{2,10}/g) || [];

        if (languages.length > 0) {
          userLang = languages[0].split('-')[0];

          if ( !GlobalFunctions.isLangAvailable(userLang) ) {
            userLang = defaultLang;
          }
        }
      }

      const lang = GlobalFunctions.getLangFromUrl( req.originalUrl, userLang );

      translate.use(lang);
      ConfigService.setCurrentLang( lang );
      this.document.documentElement.lang = lang;
    }
  }
}

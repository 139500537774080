import { Component, OnInit, Input } from '@angular/core';
import ConfigService from '@config/ConfigService';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public currentLang: string = ConfigService.getDefaultLang();

  @Input() scrollTo?: string;

  constructor() { }

  ngOnInit() {
    this.currentLang = ConfigService.getCurrentLang();
  }

}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import IAvailableTranslations from '@interfaces/IAvailableTranslations';
import { filter, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IRouteTranslations } from '@interfaces/IRouteTranslations';
import { PossibleTranslationsEnum } from '@enums/PossibleTranslationsEnum';

@Injectable()
export class CurrentRouteHelper {
  private groupsChange: Subject<string[]>;
  private routeChange: Subject<IRouteTranslations>;

  private groups: string[] = [];
  private route: IRouteTranslations;

  private notTranslatableGroups: string[] = [];
  private lastGroupTranslations: IAvailableTranslations = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.notTranslatableGroups = [
      'details',
      'career-child'
    ];

    this.groupsChange = new Subject<string[]>();
    this.routeChange = new Subject<IRouteTranslations>();
  }

  listen() {
    this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => {
            return this.activatedRoute.snapshot;
          }),
          map(route => {
            let data = [];
            while (route.firstChild) {
              data.push(route.firstChild);
              route = route.firstChild;
            }

            return data;
          })
        )
        .subscribe((routes: ActivatedRouteSnapshot[]) => {
          this.setRoute(routes)
        });
  }

  setRoute(data: ActivatedRouteSnapshot[]) {
    const titles: string[] = [];
    let description = '';
    const groups: string[] = [];
    let hasNonTranslatableGroup = false;

    data.forEach( d => {
      let routeData = d.data;

      if ( typeof routeData.meta !== 'undefined' ) {
        const meta = routeData.meta;

        if ( typeof meta.title === 'string' ) {
          titles.push(meta.title);
        }

        if ( typeof meta.description === 'string' ) {
          description = meta.description;
        }
      }

      if ( typeof routeData.group !== 'undefined' ) {
        if ( !groups.includes(routeData.group) ) {
          if ( this.notTranslatableGroups.includes(routeData.group) ) {
            if ( typeof d.params.slug !== 'undefined' ) {
              groups.push(d.params.slug);

              hasNonTranslatableGroup = true;
            }
          }
          else {
            groups.push(routeData.group);
          }
        }
      }
    });

    this.groups = groups;
    this.route = {current: `/${groups.join('/')}`};

    this.routeChange.next(this.route);
    this.groupsChange.next(this.groups);

    return groups;
  }

  // if current page is available in multiple translations, set translations for each language
  // in this case, route.current is a path of the previous page, use it if there is no available translation for this page
  public setLastGroupTranslation(translations: IAvailableTranslations) {
    this.lastGroupTranslations = translations;

    this.groups;
    this.groups.pop();

    const possibleTranslations = Object.values(PossibleTranslationsEnum);

    this.route.current = `/${this.groups.join('/')}/`;

    possibleTranslations.forEach(el => {
      if(translations[el])
        this.route[el] = `/${this.groups.join('/')}/${translations[el]}`;
    });

    this.groupsChange.next(this.groups);
    this.routeChange.next(this.route);
  }

  public removeLastGroupTranslation() {
    this.lastGroupTranslations = null;
  }

  public getGroups(): Subject<string[]> {
    return this.groupsChange;
  }

  public getCurrentRoute(): Subject<IRouteTranslations> {
    return this.routeChange;
  }


}

enum ApiConfigurationEndpointEnum {
  DelicatessenMenu = 'delicatessen_menu',
  AlaCarteMenu = 'ala_carte_menu',
  WellnessMenu = 'wellness_menu',
  MedicalWellnessMenu = 'medical_wellness_menu',
  YogaSchedule = 'yoga_schedule',
  PillowMenu = 'pillow_menu'
}

export default ApiConfigurationEndpointEnum;

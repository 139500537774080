<app-banner
  header="saunas_and_pool"
  description="saunas_and_pool_description"
  background="../../../assets/images/wellness_page/banner_tiny.jpg"
  background2x="../../../assets/images/wellness_page/banner@2x_tiny.jpg"
>
  <app-line-btn
    class="h4"
    scrollTo="swimmingPool"
    [noOffset]="true"
    bottomButton
  >
    <span>{{ 'see_more'|translate }}</span>
  </app-line-btn>
</app-banner>

<div id="swimmingPool" class="container pt-zetta mobile-padding text-simple-centered">
  <app-text-simple-centered
    header="{{ 'saunas_and_pool_text_centered_h'|translate}}"
  >
    <p class="p-responsive" [innerHTML]="'saunas_and_pool_text_centered_c'|translate|newLineBreak|removeWidows"></p>
  </app-text-simple-centered>
</div>

<div class="container mobile-padding">
  <app-indent-content-images
    [header]="'pool_and_jaccuzzi'"
    [topHeader]="'saunas_and_pool'"
    [collageType]="collageEnum.LargeTopSmall2ColBottomRight"
    [images]="imageCollage2"
  >
    <p
      class="small"
      [innerHTML]="'pool_and_jaccuzzi_description'|translate|newLineBreak|removeWidows"
    >
    </p>
  </app-indent-content-images>
</div>

<app-after-before-grey
  width="21%"
  height="1097px"
  top="100px"
  right="0"
  additionalClasses="to-top-right thick-no-responsive offset-top-sm"
>
  <div class="container mobile-padding">
    <app-indent-content-between-images
      [header]="'private_wellness_room'"
      [topHeader]="'saunas_and_pool'"
      [smallImage]="{ image: '../../../assets/images/wellness_page/wellness_room/small.jpg', image2x: '../../../assets/images/wellness_page/wellness_room/small@2x.jpg' }"
      [bigImage]="{ image: '../../../assets/images/wellness_page/wellness_room/big.jpg', image2x: '../../../assets/images/wellness_page/wellness_room/big@2x.jpg' }"
    >
      <p
        class="small"
        [innerHTML]="'private_wellness_room_description'|translate|newLineBreak|removeWidows"
      >
      </p>

      <div class="actions pt-mega pb-kilo">
        <a [routerLink]="'/:lang/wellness/private-wellness'|i18nRoute" class="btn btn-bordered">{{ 'discover_more'|translate }}</a>
      </div>
    </app-indent-content-between-images>
  </div>
</app-after-before-grey>

<div class="container mobile-padding">
  <app-indent-content-images
    [header]="'saunas'"
    [topHeader]="'saunas_and_pool'"
    [collageType]="collageEnum.LargeTopSmall3ColBottomRight"
    [images]="imageCollage"
  >
    <p
      class="small"
      [innerHTML]="'saunas_description'|translate"
    >
    </p>
  </app-indent-content-images>
</div>

<div class="container mobile-padding">
  <div class="pt-md-giga pt-lg-peta">
    <div class="row">
      <div class="col-xl-10 offset-xl-1">
        <app-icon [data]="icons"></app-icon>
      </div>
    </div>
  </div>
</div>

<app-after-before-grey
  width="44%"
  height="676px"
  top="32%"
  left="0"
  additionalClasses="to-top-left"
>
  <div class="container mobile-padding">
    <div class="pt-peta pt-md-zetta pt-lg-200">
      <div class="pt-exa">
        <div class="row">
          <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 offset-md-1 offset-lg-2">
            <app-indent-content
              [header]="'saunas_and_pool_opening_hours'"
              headerClass="light"
              wider
            >
              <div class="opening-hours pb-deca pb-md-hecto">
                <h4 class="h1">7:00<span> - </span>22:00</h4>
              </div>
              <p>{{ 'every_day'|translate}}</p>
              <p [innerHTML]="'saunas_and_pool_families_with_children'|translate|newLineBreak|removeWidows"></p>
            </app-indent-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-after-before-grey>

<div class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-gallery
      [header]="'wellness_and_spa'"
      [description]="'wellness_and_spa_gallery_description'"
      [images]="galleryImages"
      [class]="'headerGray'"
    ></app-gallery>
  </div>

<!--  <div class="pt-peta pt-md-zetta pt-lg-200">-->
<!--    <app-discover-->
<!--      [header]="'discover_also'"-->
<!--      [name]="'Intense Repair\nShea Butter'"-->
<!--      [description]="'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit in voluptate velit esse.'"-->
<!--      [price]="{ value: 49.99, currency: 'PLN' }"-->
<!--      [link]="''"-->
<!--    ></app-discover>-->
<!--  </div>-->

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 offset-xl-1">
        <div class="out-mobile-padding">
          <app-images-carousel
            [images]="carouselImages"
          >
          </app-images-carousel>
        </div>
      </div>
      <div class="col-12 col-lg-5 col-xl-4 offset-lg-1">
        <div class="pt-kilo pt-md-giga pt-lg-zero">
          <app-indent-content
            [header]="'our_team'"
          >
            <p
              class="small"
              [innerHTML]="'our_team_description'|translate|newLineBreak|removeWidows"
            >
            </p>

            <div class="actions pt-mega pb-deca">
              <a [routerLink]="'/:lang/career'|i18nRoute" class="btn btn-bordered mb-deci">{{ 'join_us'|translate }}</a>
            </div>
          </app-indent-content>
        </div>
      </div>
    </div>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-end">
      <div class="col-10 offset-lg-1 offset-xl-2">
        <h3 class="text-light-gray" [innerHTML]="'certifications'|translate|removeWidows"></h3>
      </div>
      <div class="col-12 col-md-6 col-lg-5 col-xl-4 offset-lg-1 offset-xl-2 order-1 order-md-0">
        <p
          class="pt-giga pt-lg-peta small"
          [innerHTML]="'certifications_description'|translate|newLineBreak|removeWidows"
        ></p>
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-3 offset-lg-1 order-0 order-md-1">
        <div class="pt-giga pt-md-zero">
          <a
            href="https://www.wellnessverband.de/wellness-freunde/anbieter/detail.php?we_oid=245"
            target="_blank"
            class="opacity-link"
          >
            <app-text-centered-image
              header="{{ 'award_h_04'|translate}}"
              image="/assets/images/wellness_page/certificate.png"
              image2x="/assets/images/wellness_page/certificate@2x.png"
              imgMaxHeight="118px"
              imageAlt="certificate logo"
            ></app-text-centered-image>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

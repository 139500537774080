<div
  [ngClass]="(type ? 'type-' + type : '') + ' text-offer-container'"
>
  <div
    class="text-offer-wrapper overflow-hidden"
    [ngClass]="{
      'is-wellness': isWellness,
      'singled-out': isSingledOut
    }"
  >
    <div class="image-wrapper">
      <img [src]="image" [alt]="imageAlt ? imageAlt : 'image'" />
    </div>

    <div class="text-wrapper text-main">
      <div
        *ngIf="isBestseller || isNew"
        class="stickers"
        [ngClass]="{ 'stickers-small': !isSingledOut }"
      >
        <div class="sticker new" *ngIf="isNew">
          <svg xmlns="http://www.w3.org/2000/svg" width="29.008" height="29.016" viewBox="0 0 29.008 29.016">
            <g id="nowosc_ico" transform="translate(0 0.007)">
              <path id="Path_625" data-name="Path 625" d="M1824.6,544.915" transform="translate(-1810.819 -522.715)" fill="none" stroke="#e8e8e8" stroke-miterlimit="10" stroke-width="1"/>
              <path id="Path_626" data-name="Path 626" d="M1825.214,506.1" transform="translate(-1811.302 -492.185)" fill="none" stroke="#e8e8e8" stroke-miterlimit="10" stroke-width="1"/>
              <g id="Group_432" data-name="Group 432" transform="translate(0.5 0.5)">
                <path id="Path_624" data-name="Path 624" d="M1762.114,446.567a24.684,24.684,0,0,1,2.569-2.822,14,14,0,1,1-5.028,11.28" transform="translate(-1759.655 -440.498)" fill="none" stroke="#e8e8e8" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_628" data-name="Path 628" d="M1762.011,482.731a8.446,8.446,0,0,0-.758,2.275" transform="translate(-1760.875 -473.796)" fill="none" stroke="#e8e8e8" stroke-miterlimit="10" stroke-width="1"/>
              </g>
              <line id="Line_128" data-name="Line 128" y2="19.192" transform="translate(14.501 4.905)" fill="none" stroke="#e8e8e8" stroke-width="1"/>
              <line id="Line_129" data-name="Line 129" y2="19.192" transform="translate(24.097 14.501) rotate(90)" fill="none" stroke="#e8e8e8" stroke-width="1"/>
              <line id="Line_130" data-name="Line 130" y2="15.482" transform="translate(19.974 9.027) rotate(45)" fill="none" stroke="#e8e8e8" stroke-width="1"/>
              <line id="Line_131" data-name="Line 131" y2="15.482" transform="translate(9.027 9.027) rotate(-45)" fill="none" stroke="#e8e8e8" stroke-width="1"/>
            </g>
          </svg>
          <span>{{ 'sticker_new'|translate }}</span>
        </div>
        <div class="sticker bestseller" *ngIf="isBestseller">
          <svg xmlns="http://www.w3.org/2000/svg" width="29.007" height="29.015" viewBox="0 0 29.007 29.015">
            <g id="bestseller_ico" transform="translate(0.5 0.507)">
              <path id="Path_624" data-name="Path 624" d="M1762.114,446.567a24.656,24.656,0,0,1,2.569-2.822,13.995,13.995,0,1,1-5.028,11.28" transform="translate(-1759.655 -440.498)" fill="none" stroke="#404148" stroke-miterlimit="10" stroke-width="1"/>
              <path id="Path_625" data-name="Path 625" d="M1824.6,544.915" transform="translate(-1810.736 -522.632)" fill="none" stroke="#404148" stroke-miterlimit="10" stroke-width="1"/>
              <path id="Path_626" data-name="Path 626" d="M1825.214,506.1" transform="translate(-1811.219 -492.102)" fill="none" stroke="#404148" stroke-miterlimit="10" stroke-width="1"/>
              <path id="Path_627" data-name="Path 627" d="M1792.8,467.81l.466.891a.886.886,0,0,0,.639.462l4.724.786a.88.88,0,0,1,.486,1.487l-3.357,3.4a.879.879,0,0,0-.244.748l.708,4.711a.885.885,0,0,1-1.271.919l-4.286-2.126a.89.89,0,0,0-.791,0l-4.285,2.126a.885.885,0,0,1-1.271-.919l.708-4.711a.879.879,0,0,0-.245-.748l-3.357-3.4a.88.88,0,0,1,.485-1.487l4.723-.786a.884.884,0,0,0,.639-.462l2.211-4.226a.888.888,0,0,1,1.571,0l.638,1.219" transform="translate(-1776.272 -458.654)" fill="none" stroke="#404148" stroke-miterlimit="10" stroke-width="1"/>
              <path id="Path_628" data-name="Path 628" d="M1761.982,482.731a8.12,8.12,0,0,0-.729,2.187" transform="translate(-1760.89 -473.766)" fill="none" stroke="#404148" stroke-miterlimit="10" stroke-width="1"/>
            </g>
          </svg>
          <span>{{ 'sticker_bestseller'|translate }}</span>
        </div>
      </div>
      <ng-container *ngIf="link && onLinkClick && itemId">
        <a
          [routerLink]="link"
          (click)="onLinkClick(itemId)"
        ></a>
      </ng-container>
      <ng-container *ngIf="link && !onLinkClick && !itemId">
        <a
          [routerLink]="link"
        ></a>
      </ng-container>

      <h3 *ngIf="header">{{header}}</h3>
      <ng-content></ng-content>
    </div>
  </div>
</div>

<div class="callendarium-container" *ngIf="type===1 && callendar">
  <div class="callendarium">
    <div class="pb-kilo">
      <h4 class="h3">{{'events_calendar'|translate}}</h4>
    </div>
    <a class="btn btn-bordered btn-white" target="_blank" href="{{ callendar|apiUrl }}">{{'check_events_calendar'|translate}}</a>
  </div>
</div>

import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '@services/auth/user.service';
import {filter, takeUntil} from 'rxjs/operators';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {PopupContentComponent} from '../../../_shared/popup-content/popup-content.component';

@Component({
  selector: 'app-loyalty-program-popup',
  templateUrl: './loyalty-program-popup.component.html',
  styleUrls: ['./loyalty-program-popup.component.scss']
})
export class LoyaltyProgramPopupComponent extends SubscribeHelper implements OnInit {

  @ViewChild('popupLoyaltyProgram') popupLoyaltyProgram: PopupContentComponent;

  loyaltyProgram = false;

  private loyaltyProgramStorageKey = 'loyalty-program-modal';

  constructor(
    private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.userService.isLoggedIn().pipe(
      takeUntil(this.componentDestroyed$),
      filter(isLoggedIn => isLoggedIn),
    ).subscribe(_ => {
      const user = this.userService.userValue;
      if (!user.loyaltyProgram) {
        this.loyaltyProgram = !user.loyaltyProgram;

        if (JSON.parse(localStorage.getItem(this.loyaltyProgramStorageKey)) !== true) {
          setTimeout(() => {
            this.popupLoyaltyProgram.showPopup();
          }, 2000);
        }
      }
    });
  }

  onCloseModal() {
    localStorage.setItem(this.loyaltyProgramStorageKey, 'true');
  }

  closeModal() {
    this.popupLoyaltyProgram.closePopup();
  }

}

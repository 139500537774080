<div
  *ngIf="!isReservation"
  class="booking-bar fixed-bottom"
  [ngClass]="{ 'active': isScrolled }"
>
  <div class="container">
    <div class="row">
      <div class="col-xl-10 offset-xl-1">
        <form [formGroup]="bookingForm" class="d-flex justify-content-around" (ngSubmit)="onSubmit()">
          <div class="row align-items-center justify-content-between w-100">
            <div class="col-3 col-lg-auto">
              <app-datepicker-popup
                inputId="fromDate"
                control="fromDate"
                [group]="bookingForm"
                [showCloseBtn]="false"
                fieldName="form_arrival"
                class="booking-bar-picker"
                (triggerChange)="changeDate($event)"
                #input
              ></app-datepicker-popup>
            </div>
            <div class="col-3 col-lg-auto">
              <app-datepicker-popup
                inputId="toDate"
                control="toDate"
                [group]="bookingForm"
                [showCloseBtn]="false"
                [minDate]="toDateStart"
                fieldName="form_check_out"
                class="booking-bar-picker"
              ></app-datepicker-popup>
            </div>
            <div class="col-3 col-lg-auto">
              <app-counter
                text="form_adults"
                [startValue]="numberOfAdults"
                [minValue]="minAdultsValue"
                [maxValue]="maxAdultsValue"
                (value)="setNumberOf($event, 0)"
                [class]="isScrolled ? 'active' : null"
              ></app-counter>
            </div>
            <div class="col-3 col-lg-auto">
              <app-counter
                text="form_children"
                [startValue]="numberOfChildren"
                [minValue]="minChildrenValue"
                [maxValue]="maxChildrenValue"
                (value)="setNumberOf($event, 1)"
                [class]="isScrolled ? 'active' : null"
              ></app-counter>
            </div>
            <div class="col-12 col-lg-auto text-center">
              <button
                class="btn btn-bordered mt-4 mt-lg-0"
                [ngClass]="isScrolled ? 'on-white' : 'btn-white'"
              >
                {{ 'check_available_rooms' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

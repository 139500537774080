<app-section-introduction [text]="'useful_information_introduction'"></app-section-introduction>
<div class="bg-white pt-kilo">
  <div class="container mobile-padding">
    <div class="row">
      <div class="col-xl-10 offset-xl-1">
        <app-section-header header="activity" subHeader="activity_desc"></app-section-header>
        <app-event-scheduler [eventsData]="events$ | async" (onEventClick)="setElementInfo($event)"></app-event-scheduler>
      </div>
    </div>
  </div>
</div>
<div class="bg-white pt-mega pt-md-giga pb-giga">
  <div class="container mobile-padding">
    <div class="row">
      <div class="col-xl-10 offset-xl-1">
        <app-section-header header="useful_information" subHeader="useful_information_desc"></app-section-header>
        <div class="row pt-hecto">
          <div class="col-lg-5 text-center">
            <img
              class="img-fluid hotel-img mt-lg-2 position-relative"
              [defaultImage]="'/assets/images/placeholders/placeholder535x304.png'"
              [lazyLoad]="
              hasSrcSetSupport
              ? '/assets/images/reservations/loyalty-program/useful-information/shuum-hotel.jpg 1x, /assets/images/reservations/loyalty-program/useful-information/shuum-hotel@2x.jpg 2x'
              : '/assets/images/reservations/loyalty-program/useful-information/shuum-hotel.jpg'"
              [useSrcset]="hasSrcSetSupport"
              alt="Shuum hotel"
            />
          </div>
          <div class="col-lg-7 bg-light-gray position-relative grey-square-after">
            <div class="contact-wrapper pt-kilo pt-lg-0 pb-kilo position-relative">
              <h3 class="offset-md-1 offset-lg-2 heading" [innerHTML]="'contact_title_double_header' | translate"></h3>
              <div class="row">
                <div class="col-sm-6 col-md-5 offset-md-1 offset-lg-2">
                  <h4>{{ 'contact_text_subtle_h_02'|translate }}</h4>
                  <div class="pt-deci">
                    <p>{{ 'contact_tel'|translate }} <a class="text-decoration-none" href="tel:+48943554000">+48 94 355 4000</a></p>
                    <p>{{ 'contact_email'|translate }}: <a class="text-decoration-none" href="mailto:rezerwacje@shuumhotel.pl">rezerwacje@shuumhotel.pl</a></p>
                  </div>

                  <h4 [innerHTML]="'north_restaurant'|translate|newLineBreak"></h4>
                  <div class="pt-deci">
                    <p>7:30 - 11:00 - {{ 'breakfast' | translate }}</p>
                    <p>13:00 - 23:00 - <span [innerHTML]="'north_restaurant_contact' | translate | removeWidows"></span></p>
                    <p>17:30 - 20:00 - {{ 'dinner' | translate }}</p>
                    <p>{{ 'open' | translate }}: {{ 'every_day' | translate | lowercase }}</p>
                  </div>

                  <h4>{{ 'contact_text_subtle_h_05'|translate }}</h4>
                  <div class="pt-deci">
                    <p>{{ 'contact_tel'|translate }} <a class="text-decoration-none" href="tel:+48943554003">+48 94 355 4003</a></p>
                    <p>{{ 'contact_email'|translate }}: <a class="text-decoration-none" href="mailto:biuro@shuumhotel.pl">biuro@shuumhotel.pl</a></p>
                  </div>

                  <h4>{{ 'hote_ day'|translate }}</h4>
                  <div class="pt-deci">
                    <p>{{ 'hote_ day_desc' | translate }}</p>
                  </div>

                  <h4>{{ 'room_service'|translate }}</h4>
                  <div class="pt-deci">
                    <p>
                      <app-loader [size]="30" [opacity]="'0.7'" [center]="true" *ngIf="alaCarteMenuFile === null"></app-loader>

                      <ng-container *ngIf="alaCarteMenuFile?.value">
                        <a [href]="alaCarteMenuFile?.value|apiUrl" target="_blank">{{ 'room_service_desc'|translate }}</a>
                      </ng-container>
                      <ng-container *ngIf="alaCarteMenuFile !== null && !alaCarteMenuFile?.value">
                        <span class="small">{{ 'menu_is_currently_unavailable'|translate }}</span>
                      </ng-container>
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-md-5">
                  <h4>{{ 'contact_text_subtle_h_01'|translate }}</h4>
                  <div class="pt-deci">
                    <p>{{ 'contact_tel'|translate }} <a class="text-decoration-none" href="tel:+48943554000">+48 94 355 4000</a></p>
                    <p>{{ 'contact_email'|translate }}: <a class="text-decoration-none" href="mailto:recepcja@shuumhotel.pl">recepcja@shuumhotel.pl</a></p>
                  </div>

                  <h4>{{ 'contact_text_subtle_h_04'|translate }}</h4>
                  <div class="pt-deci">
                    <p>{{ 'contact_tel'|translate }} <a class="text-decoration-none" href="tel:+48943554050">+48 94 355 4050</a></p>
                    <p>{{ 'contact_email'|translate }}: <a class="text-decoration-none" href="mailto:wellness@shuumhotel.pl">wellness@shuumhotel.pl</a></p>
                    <p>{{ 'open' | translate }}: 9:00 - 21:00</p>
                  </div>

                  <h4>{{ 'lobby_bar'|translate }}</h4>
                  <div class="pt-deci">
                    <p>11:00 - 23:00</p>
                    <p>{{ 'open' | translate }}: {{ 'every_day' | translate | lowercase }}</p>
                  </div>

                  <h4>{{ 'pool'|translate }}</h4>
                  <div class="pt-deci">
                    <p>7:00 - 22:00</p>
                    <p>{{ 'open' | translate }}: {{ 'every_day' | translate | lowercase }}</p>
                    <p [innerHTML]="'pool_desc' | translate | removeWidows"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="pt-giga pb-deca">
  <div class="container mobile-padding">
    <div class="row">
      <div class="col-xl-10 offset-xl-1">
        <app-section-header header="shuum_deli" subHeader="shuum_deli_desc"></app-section-header>
        <div class="row align-items-center">
          <div class="col-md-6 pt-kilo pb-kilo">
            <img
              class="img-fluid"
              [defaultImage]="'/assets/images/placeholders/placeholder610x619.png'"
              [lazyLoad]="
              hasSrcSetSupport
              ? '/assets/images/reservations/loyalty-program/useful-information/delikatesy.jpeg 1x, /assets/images/food_and_wine/delicatessen/bakery_img@2x.png 2x'
              : '/assets/images/reservations/loyalty-program/useful-information/delikatesy.jpeg'
            "
              [useSrcset]="hasSrcSetSupport"
              [alt]="'delicatessen_bakery_header'|translate"
            />
          </div>
          <div class="col-md-6 col-xxl-4">
            <p class="small-light" [innerHTML]="'shuum_deli_text' | translate | removeWidows"></p>
            <div class="actions pt-kilo">
              <app-loader [size]="30" [opacity]="'0.7'" [center]="false" *ngIf="delicatessenMenuFile === null"></app-loader>

              <ng-container *ngIf="delicatessenMenuFile?.value">
                <a
                  [href]="delicatessenMenuFile?.value|apiUrl"
                  target="_blank"
                  class="btn btn-bordered"
                >{{ 'download_pdf' | translate }}</a>
              </ng-container>
              <ng-container *ngIf="delicatessenMenuFile !== null && !delicatessenMenuFile?.value">
                <span class="small font-weight-light">{{ 'menu_is_currently_unavailable'|translate }}</span>
              </ng-container>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-offer-stays [offers]="offers$ | async" [loading]="offersLoading"></app-offer-stays>

<app-contact-form [bookingType]="bookingType" [bookingNames]="eventNames"></app-contact-form>

import { Component, OnInit } from '@angular/core';
import ConfigService from '@config/ConfigService';
import {IConfiguration} from '@interfaces/IConfiguration';
import ApiConfigurationEndpointEnum from '@enums/ApiConfigurationEndpointEnum';
import {filter, finalize, map, takeUntil, tap} from 'rxjs/operators';
import {catchError} from 'rxjs/internal/operators';
import {ConfigurationService} from '@services/configuration.service';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import { IBookingType } from 'src/app/_subprojects/reservation/_interfaces/IBookingType';
import IEvent from '@interfaces/IEvent';
import { EventService } from '@services/event.service';
import IFilter from '@interfaces/IFilter';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { ContactFormService } from 'src/app/_subprojects/reservation/_services/contact-form.service';
import { OfferService } from '@services/offer.service';
import IOrder from '@interfaces/IOrder';
import { IOffer } from '@interfaces/IOffer';

@Component({
  selector: 'app-useful-information',
  templateUrl: './useful-information.component.html',
  styleUrls: ['./useful-information.component.scss']
})
export class UsefulInformationComponent extends SubscribeHelper implements OnInit {

  bookingType: IBookingType[] = [
    {
      id: 7,
      label: 'book_event',
      extra: null,
      email: 'recepcja@shuumhotel.pl',
      requiredFields: ['bookingType', 'date', 'time', 'email', 'phone']
    },
    {
      id: 8,
      label: 'book_offer_stay',
      extra: null,
      email: 'rezerwacje@shuumhotel.pl',
      requiredFields: ['bookingType', 'email', 'phone']
    }
  ];

  public hasSrcSetSupport: boolean;
  delicatessenMenuFile: IConfiguration = null;
  alaCarteMenuFile: IConfiguration = null;

  events$: Observable<IEvent[]>;

  eventNames: { [key: string]: string }[] = [];

  offers$: Observable<IOffer[]>;
  offersLoading: boolean;

  constructor(
    private apiConfiguration: ConfigurationService,
    private eventService: EventService,
    private scrollToService: ScrollToService,
    private contactFormService: ContactFormService,
    private offerService: OfferService
  ) {
    super();
    this.hasSrcSetSupport = ConfigService.getHasSrcSetSupport();
  }

  ngOnInit(): void {
    this.fetchEvents();
    this.getOffers();
    this.getDelicatessenMenuFile();
    this.getAlaCarteMenuFile();
  }

  fetchEvents() {
    const filters: IFilter[] = [];

    filters.push({
      key: 'eventType',
      value: 0
    });

    this.events$ = this.eventService.getList(filters, [], 100).pipe(
      tap(events => events.filter(event => {
        const date = moment(event.dateStart);
        const isTodayOrFuture = date.isSameOrAfter(moment(), 'day');
        return isTodayOrFuture && event.isSignUpRequired;
      }).map(event => {
        if (!this.eventNames.some(e => e.name === event.title)) {
          this.eventNames.push({
            type: 'book_event',
            name: event.title,
            description: event.description
          });
        }
      }))
    );
  }

  getOffers() {
    this.offersLoading = true;

    const filters: IFilter[] = [];
    const orders: IOrder[] = [];

    orders.push({
      field: 'updatedAt',
      type: 'desc'
    });

    this.offers$ = this.offerService.getList(filters, orders, 2)
      .pipe(
        finalize(() => this.offersLoading = false),
        tap(offers => offers.map(offer => {
          if (!this.eventNames.some(e => e.name === offer.name)) {
            this.eventNames.push({
              type: 'book_offer_stay',
              name: offer.name,
              description: offer.description
            });
          }
        }))
      )
  }

  getDelicatessenMenuFile(): void {
    this.apiConfiguration
      .getConfiguration(ApiConfigurationEndpointEnum.DelicatessenMenu)
      .pipe(
        takeUntil(this.componentDestroyed$),
        catchError(err => {
          this.delicatessenMenuFile = { id: null, value: '', iso: '', name: '' };
          return err;
        })
      )
      .subscribe((res: IConfiguration) => {
        this.delicatessenMenuFile = res;
      });
  }

  getAlaCarteMenuFile(): void {
    this.apiConfiguration
      .getConfiguration(ApiConfigurationEndpointEnum.AlaCarteMenu)
      .pipe(
        takeUntil(this.componentDestroyed$),
        catchError(err => {
          this.alaCarteMenuFile = { id: null, value: '', iso: '', name: '' };
          return err;
        })
      )
      .subscribe((res: IConfiguration) => {
        this.alaCarteMenuFile = res;
      });
  }

  setElementInfo = ( { data, e } ) => {
    if ( window ) {
      const date = moment(data.start);
      const isTodayOrFuture = date.isSameOrAfter(moment(), 'day');

      if (isTodayOrFuture && data.isSignUpRequired) {
        this.contactFormService.setBookingType({
          id: 7,
          extra: {
            name: data.title,
            description: data.description,
            date: data.start,
            time: data.time
          }
        });

        this.scrollTo(e);
      }
    }
  }

  scrollTo( e: MouseEvent ): void {
    e.preventDefault();

    const scrollToConfig = {
      target: 'contactForm',
      duration: 300
    };

    this.scrollToService
      .scrollTo(scrollToConfig);
  }
}
